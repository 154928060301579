<template>
  <CCard>
    <CCardHeader>
      {{ $t("user.profile.image.title") }}
      <div class="card-header-actions">
        <CButton
          variant="outline"
          color="primary"
          size="sm"
          :pressed.sync="imgForm.active"
          ><CIcon name="cil-pencil" />&nbsp;{{
            $t("common.editor.button.edit")
          }}</CButton
        >
      </div>
    </CCardHeader>
    <CCardBody>
      <img
        v-if="!imgForm.active && user && user.image_cropped"
        v-bind:src="user.image_cropped"
        class="c-avatar-img"
      />
      <cropper
        ref="cropper"
        v-if="imgForm.active && imgForm.body.image"
        :stencil-component="$options.components.CircleStencil"
        class="cropper"
        :src="imgForm.body.image"
        :stencil-props="{
          aspectRatio: 10 / 10,
        }"
        @change="change"
      ></cropper>
    </CCardBody>
    <CCardFooter v-if="imgForm.active">
      <input
        type="file"
        ref="file"
        @change="uploadImage($event)"
        accept="image/*"
        class="d-none"
      />
      <vue-ladda
        :loading="imgForm.loading"
        data-style="zoom-in"
        button-class="btn btn-primary btn-sm"
        @click.prevent="SaveImg"
        ><CIcon name="cil-save" />&nbsp;{{
          $t("common.editor.button.save")
        }}</vue-ladda
      >
      <vue-ladda
        :loading="imgForm.loading"
        data-style="zoom-in"
        button-class="btn btn-primary btn-sm"
        @click.prevent="$refs.file.click()"
        ><CIcon name="cil-image-plus"
      /></vue-ladda>
      <vue-ladda
        :loading="imgForm.loading"
        data-style="zoom-in"
        button-class="btn btn-primary btn-sm"
        @click.prevent="RemoveImg"
        ><CIcon name="cil-trash"
      /></vue-ladda>
    </CCardFooter>
  </CCard>
</template>

<script>
// import { CircleStencil, Cropper } from "vue-advanced-cropper";
import { DataManager } from "@syncfusion/ej2-data";
import { Cropper } from "vue-advanced-cropper";
import DrfAdaptor2 from "../../http/EJ2_adapter2";
import { apiUrl } from "../../http";
let dm = new DataManager({
  url: apiUrl(`/api/auth/user/`),
  adaptor: new DrfAdaptor2(),
  crossDomain: true,
});
export default {
  name: "ProfileDataImage",
  data() {
    return {
      imgForm: {
        loading: false,
        active: false,
        body: { image_cropping: null, image: null },
        errors: {},
      },
    };
  },
  mounted() {
    this.imgForm.body.image_cropping = this.user.image_cropping;
    this.imgForm.body.image = this.user.image;
  },
  computed: {
    user() {
      // console.log(this.$auth.user());
      return this.$auth.user();
    },
  },
  methods: {
    uploadImage(event) {
      // console.log("load image");
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imgForm.body.image = e.target.result;
          this.imgForm.body.image_cropping = "";
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    // :default-position="defaultPosition"
    // :default-size="defaultSize"
    // defaultPosition() {
    //   if (this.imgForm.body.image_cropping) {
    //     let vals = this.imgForm.body.image_cropping.split(",");
    //     return {
    //       left: vals[0],
    //       top: vals[1],
    //     };
    //   }
    //   return null;
    // },
    // defaultSize() {
    //   if (this.imgForm.body.image_cropping) {
    //     let vals = this.imgForm.body.image_cropping.split(",");
    //     return {
    //       width: (vals[3] - vals[0])/2,
    //       height: (vals[4] - vals[1])/2,
    //     };
    //   }
    //   return null;
    // },
    change({ coordinates }) {
      // console.log(coordinates, canvas);
      this.imgForm.body.image_cropping = [
        coordinates.left,
        coordinates.top,
        coordinates.left + coordinates.width,
        coordinates.top + coordinates.height,
      ].join(",");
    },
    SaveImg() {
      // console.log("save_data")
      // console.log(this.dataForm.body)
      this.imgForm.loading = true;
      this.imgForm.errors = {};
      // this.imgForm.body.image_cropping=""
      let formData = {};
      if (
        this.imgForm.body.image.split("?")[0] != this.user.image.split("?")[0]
      ) {
        formData.image = this.imgForm.body.image;
      }
      if (this.imgForm.body.image_cropping != this.user.image_cropping) {
        formData.image_cropping = this.imgForm.body.image_cropping;
      }
      console.log({
        image: this.imgForm.body.image,
        image_cropping: this.imgForm.body.image_cropping,
      });
      console.log(formData);
      dm.update("", formData).then(
        (e) => {
          // console.log("after update");
          // console.log(e);
          this.$auth.user(e);
          this.imgForm.loading = false;
          this.imgForm.active = false;
          this.$store.dispatch("toaster/add", {
            title: this.$t("user.profile.image.toast.ok.title"),
            text: this.$t("user.profile.image.toast.ok.message"),
            autohide: true,
          });
        },
        (reason) => {
          let errors = JSON.parse(reason[0].error.response);
          this.imgForm.errors = errors;
          this.imgForm.loading = false;
        }
      );
    },
    RemoveImg() {
      // console.log("save_data")
      // console.log(this.dataForm.body)
      this.imgForm.loading = true;
      this.imgForm.errors = {};
      // this.imgForm.body.image_cropping=""
      this.imgForm.body = {
        image: "",
        image_cropping: "",
      };
      console.log(this.imgForm.body);
      dm.update("", this.imgForm.body).then(
        (e) => {
          // console.log("after update");
          // console.log(e);
          this.$auth.user(e);
          this.imgForm.loading = false;
          this.imgForm.active = false;
          this.$store.dispatch("toaster/add", {
            title: this.$t("user.profile.image.toast.remove.title"),
            text: this.$t("user.profile.image.toast.remove.message"),
            autohide: true,
          });
        },
        (reason) => {
          let errors = JSON.parse(reason[0].error.response);
          this.imgForm.errors = errors;
          this.imgForm.loading = false;
        }
      );
    },
  },
  components: {
    Cropper,
  },
};
</script>

<style scoped>
.cropper {
  /* height: 600px; */
  background: #ddd;
}
</style>
